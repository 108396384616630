const dev = {
  UserPoolId: 'eu-west-1_vwaWuRcab',
  ClientId: '4toa21a77j0b9d2tn26obv0d34',
  baseURL: 'https://api.uat.py-insights.com/',
  gaTrackingID: 'UA-43751009-17',
  captchaKey: '6Ld9aKgZAAAAAMEaaQaZrpOr1YpvJAPoy14V_zes',
  projectsRefreshTime: 1000 * 60 * 20, // 20 minutes
  qrUrl: 'https://n1vmi8wang.execute-api.eu-west-1.amazonaws.com/UAT/util/qr?',
}

const prod = {
  UserPoolId: 'eu-west-1_nSHyy9BBT',
  ClientId: '6fpe3egpkagfmmdbphnl3oibf6',
  baseURL: 'https://api.py-insights.com/',
  gaTrackingID: 'UA-43751009-16',
  captchaKey: '6LctytkUAAAAAIn9kkusswSsLr5sFaddctdLOlAQ',
  projectsRefreshTime: 1000 * 60 * 60, // 60 minutes
  qrUrl: 'https://sf3ebfd9yg.execute-api.eu-west-1.amazonaws.com/PROD/util/qr?',
}

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev

export default {
  ...config,
}
