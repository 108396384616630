import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  postQuote,
  getQuotes,
  setupQuote,
  putQuote,
  getStudies,
  markProjectComplete,
  getAssignedToProject,
  postAssignToProject,
  postDeleteAssignFromProject,
  recruitmentDone,
} from 'services/instants'
import { push } from 'react-router-redux'
import actions from './actions'
import { notification } from 'antd'

const POST_QUOTE_SETUP_API = '/quote/setup'
const GET_INSTANTS_STUDIES = '/instants/studies'
const INSTANTS_QUOTE_API = 'instants/quote'
const QUOTE_API = 'quote'

export function* SEND_QUOTE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const success = yield call(postQuote, payload, QUOTE_API)

  if (success) {
    if (payload.type === 'duplicate') {
      yield put({
        type: actions.UPDATE_QUOTE,
        payload: {
          quote: success,
          source: payload.source,
        },
      })
      yield GET_QUOTES()
    } else if (payload.type === 'update' && !payload.noUpload) {
      yield GET_QUOTES()
      notification.success({
        message: 'Quote was updated successfully',
      })
    } else {
      let  message= payload.source.toUpperCase() + " "+ success?.studyId + " created successfully"
      if(payload?.partOfQualify) message="QUALIFY and SNAPSHOT " + success?.studyId + " created successfully"
      
      if(!payload?.noUpload){
        notification.info({
          message: 'Success',
          description: message,
        })
      }
      yield put({
        type: actions.SET_STATE,
        payload: {
          resetForm: true,
        },
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  if (payload.type === 'new' && !payload.noUpload) yield put(push('/account/instants/myquotes'))

  if (payload.noUpload) return success
}

export function* GET_QUOTES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(getQuotes, INSTANTS_QUOTE_API)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotes: success,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SETUP_QUOTE(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(setupQuote, payload.data, POST_QUOTE_SETUP_API)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        closeSetupModal: true,
      },
    })

    yield put({
      type: actions.SET_STATUS,
      payload: {
        status: payload.data.isDraft ? 'setupDraft' : 'setup',
        id: payload.data.id,
        source: payload.data.source.split('_')[0],
      },
    })

    if (success.refresh) {
      window.location.reload()
    } else {
      yield GET_QUOTES()
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* PUT_STATUS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(putQuote, payload.data, QUOTE_API)

  if (success) {
    notification.success({ message: "Success" })
    yield put({
      type: actions.SET_STATUS,
      payload: {
        status: payload.data.status,
        id: payload.data.id,
        source: payload.data.source,
      },
    })

    if (payload.data.status === 'underReview')
      yield put({
        type: actions.SET_STATE,
        payload: {
          goLiveModalVisible: true,
          liveQuoteId: payload.data.id,
        },
      })

    yield GET_QUOTES()
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_STUDIES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(getStudies, GET_INSTANTS_STUDIES)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        studies: success.results,
        projectsUpdatedDate: success.updatedDate,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_ASSIGNED_TO_PROJECT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const result = yield call(getAssignedToProject, payload.orgId, payload.type)

  if (result) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        assigned: result,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* ASSIGN_TO_PROJECT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const result = yield call(postAssignToProject, payload)

  if (result) {
    yield put({
      type: actions.UPDATE_ASSIGNED_TO_PROJECT,
      payload: {
        email: payload.email,
        type: 'add',
      },
    })

    if (payload.action === 'admin') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          successResult: true,
        },
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_ASSIGN_FROM_PROJECT({ payload }) {
  const result = yield call(postDeleteAssignFromProject, payload)

  if (result) {
    yield put({
      type: actions.UPDATE_ASSIGNED_TO_PROJECT,
      payload: {
        email: payload.email,
      },
    })

    if (payload.action === 'admin') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          successResult: true,
        },
      })
    }
  }
}

export function* COMPLETE_PROJECT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(markProjectComplete, { ...payload })

  if (success) {
    window.location.reload()
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* RECRUITMENT_DONE({ payload }) {
  if (payload) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })

    yield call(recruitmentDone, { ...payload })

    yield GET_STUDIES()
  }
}

export function* POST_QUALIFY_AND_SNAPSHOT_QUOTE({ payload }) {
  const qualifyData = payload.qualify
  const snapshotData = payload.snapshot

  const response = yield SEND_QUOTE({ payload: qualifyData })

  snapshotData.quote.studyId = response.studyId

  yield SEND_QUOTE({ payload: snapshotData, notification: false })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEND_QUOTE, SEND_QUOTE),
    takeEvery(actions.GET_QUOTES, GET_QUOTES),
    takeEvery(actions.SETUP_QUOTE, SETUP_QUOTE),
    takeEvery(actions.PUT_STATUS, PUT_STATUS),
    takeEvery(actions.GET_STUDIES, GET_STUDIES),
    takeEvery(actions.COMPLETE_PROJECT, COMPLETE_PROJECT),
    takeEvery(actions.GET_ASSIGNED_TO_PROJECT, GET_ASSIGNED_TO_PROJECT),
    takeEvery(actions.ASSIGN_TO_PROJECT, ASSIGN_TO_PROJECT),
    takeEvery(actions.DELETE_ASSIGN_FROM_PROJECT, DELETE_ASSIGN_FROM_PROJECT),
    takeEvery(actions.RECRUITMENT_DONE, RECRUITMENT_DONE),
    takeEvery(actions.POST_QUALIFY_AND_SNAPSHOT_QUOTE, POST_QUALIFY_AND_SNAPSHOT_QUOTE),
  ])
}
