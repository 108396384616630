import actions from './actions'

const initialState = {
  currency: '$',
  dataCost: 0,
  analyticsCost: 0,
  recruitmentCost: 0,
  loading: false,
  resetForm: false,
  closeSetupModal: false,
  goLiveModalVisible: false,
  quotes: [],
  projects: [],
  liveQuoteId: null,
  assigned: [],
  successResult: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA_COST:
      return { ...state, dataCost: action.dataCost }
    case actions.SET_ANALYTICS_COST:
      return { ...state, analyticsCost: action.analyticsCost }
    case actions.SET_CURRENCY:
      return { ...state, currency: action.currency }
    case actions.SET_STATUS: {
      const newQuotes = [...state.quotes]
      const quoteIndexToReplace = newQuotes.findIndex((quote) => quote.id === action.payload.id)

      newQuotes[quoteIndexToReplace].status = action.payload.status

      return { ...state, closeSetupModal: true, quotes: newQuotes }
    }

    case actions.UPDATE_QUOTE: {
      const newQuotes = [...state.quotes]
      const quoteIndexToReplace = newQuotes.findIndex(
        (quote) => quote.id === action.payload.quote.id,
      )

      if (quoteIndexToReplace === -1) {
        newQuotes.push(action.payload.quote)
      } else {
        newQuotes[quoteIndexToReplace] = action.payload.quote
      }

      return { ...state, quotes: newQuotes }
    }
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET_STATE:
      return { ...initialState }
    case actions.UPDATE_ASSIGNED_TO_PROJECT: {
      let newAssigned = [...state.assigned]

      if (action.payload.type === 'add') {
        newAssigned.push(action.payload.email)
      } else {
        newAssigned = newAssigned.filter((email) => email !== action.payload.email)
      }

      return { ...state, assigned: newAssigned }
    }
    default:
      return state
  }
}
