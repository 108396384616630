import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js'
import { notification } from 'antd'
import axios from '../axios'
import userPool from '../userpool'

const ACCOUNTS_API = 'account'
const GET_CAPTCHA_CODE = '/auth/gCaptcha'

const verificationMessageTitle = 'Verification needed'
const verificationMessageBody = 'Please verify your email. Click here to resend email'
const verificationMessageDuration = 10

export async function login(email, password) {
  const authenticationData = {
    Username: email?.toLowerCase(),
    Password: password,
  }
  const authenticationDetails = new AuthenticationDetails(authenticationData)

  const userData = {
    Username: email?.toLowerCase(),
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  return new Promise((resolve) =>
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        resolve(true)
      },
      onFailure: (err) => {
        if (err.code === 'UserNotConfirmedException') {
          resendConfirmationEmail(cognitoUser)
        } else if (err.code === 'NotAuthorizedException') {
          notification.warning({
            message: 'Incorrect password',
            description: 'Please check your password and try again',
          })
        } else if (err.code === 'UserNotFoundException') {
          notification.warning({
            message: 'Incorrect email',
            description: 'Please check your email and try again',
          })
        } else {
          notification.warning({
            message: err.code,
            description: err.message,
          })
        }

        resolve(false)
      },
    }),
  )
}

export async function signup(payload) {
  return new Promise((resolve) => {
    const attributeList = []

    const firstName = {
      Name: 'custom:first_name',
      Value: payload.first_name,
    }

    const lastName = {
      Name: 'custom:last_name',
      Value: payload.last_name,
    }

    const gender = {
      Name: 'custom:user_gender',
      Value: payload.gender,
    }

    const jobFunction = {
      Name: 'custom:job_function',
      Value: payload.job_function,
    }

    const country = {
      Name: 'custom:country',
      Value: payload.country,
    }

    const companyName = {
      Name: 'custom:company_name',
      Value: payload.company_name,
    }

    const attributeFirstName = new CognitoUserAttribute(firstName)
    const attributeLastName = new CognitoUserAttribute(lastName)
    const attributeGender = new CognitoUserAttribute(gender)
    const attributeJobFunction = new CognitoUserAttribute(jobFunction)
    const attributeCountry = new CognitoUserAttribute(country)
    const attributeCompanyName = new CognitoUserAttribute(companyName)

    attributeList.push(attributeFirstName)
    attributeList.push(attributeLastName)
    attributeList.push(attributeGender)
    attributeList.push(attributeJobFunction)
    attributeList.push(attributeCountry)
    attributeList.push(attributeCompanyName)

    const validationData = [{ Name: 'captcha', Value: payload.captcha }]

    userPool.signUp(
      payload.email,
      payload.password,
      attributeList,
      validationData,
      (err, result) => {
        if (err) {
          notification.warning({
            message: err.code,
            description: err.message,
          })
          resolve(false)
          return
        }
        const cognitoUser = result.user

        const authenticationData = {
          Username: payload.email,
          Password: payload.password,
        }
        const authenticationDetails = new AuthenticationDetails(authenticationData)

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: () => {
            resolve(true)
          },
          onFailure: (error) => {
            if (error.code === 'UserNotConfirmedException') {
              resendConfirmationEmail(cognitoUser)
              resolve(error.code)
            } else {
              notification.warning({
                message: error.code,
                description: error.message,
              })
              resolve(false)
            }
          },
        })
      },
    )
  })
}

export async function currentAccount() {
  const cognitoUser = userPool.getCurrentUser()
  function getCurrentUser() {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          logout()
          reject(err)
        }
        axios
          .get(ACCOUNTS_API, {
            headers: {
              Authorization: `${session.idToken.jwtToken}`,
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            notification.error({
              message: error.message,
              description: error.response?.data,
            })
            // TODO
            logout()
            resolve(false)
            // resolve(true)
          })
      })
    })
  }
  if (cognitoUser != null) return getCurrentUser()

  return false
}

export async function captchaScore(captchaResponse) {
  return new Promise((resolve) => {
    axios
      .get(`${GET_CAPTCHA_CODE}?response=${captchaResponse}`)
      .then(() => {
        resolve(true)
      })
      .catch((error) => {
        notification.error({
          message: error,
        })
        resolve(false)
      })
  })
}

export async function putProfile(payload) {
  const cognitoUser = userPool.getCurrentUser()
  function getCurrentUser() {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          logout()
          reject(err)
        }
        axios
          .put(ACCOUNTS_API, payload.data, {
            headers: {
              Authorization: `${session.idToken.jwtToken}`,
            },
          })
          .then(() => {
            notification.success({
              message: `${payload.type} updated successfully`,
            })
            resolve(true)
          })
          .catch((error) => {
            notification.warning({
              message: error,
              description: error.response,
            })
            resolve(false)
          })
      })
    })
  }
  if (cognitoUser != null) return getCurrentUser()

  return false
}

export async function logout() {
  const cognitoUser = userPool.getCurrentUser()

  if (cognitoUser !== null) {
    cognitoUser.signOut()
  }
}

const resendConfirmationEmail = (cognitoUser) => {
  notification.info({
    message: verificationMessageTitle,
    description: verificationMessageBody,
    duration: verificationMessageDuration,
    onClick: () => {
      cognitoUser.resendConfirmationCode((error) => {
        if (error) {
          return
        }

        notification.info({
          message: 'Email send',
          description: 'Please check your inbox',
        })
      })
    },
  })
}
