import { CognitoUserPool } from 'amazon-cognito-identity-js'
import config from 'envVariables'

// Client Portal PROD pool
const userPool = new CognitoUserPool({
  UserPoolId: config.UserPoolId, // Your user pool id here
  ClientId: config.ClientId, // Your client id here
})

export default userPool
