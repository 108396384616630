import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import admin from './admin/reducers'
import menu from './menu/reducers'
import veriglif from './veriglif/reducers'
import settings from './settings/reducers'
import snapshot from './snapshot/reducers'
import qualify from './qualify/reducers'
import via from './via/reducers'
import home from './home/reducers'
import analytics from './analytics/reducers'
import instants from './instants/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    admin,
    menu,
    veriglif,
    settings,
    snapshot,
    qualify,
    via,
    home,
    analytics,
    instants,
  })
