import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getProjects, postQuote, getQuotes, putQuote, setupQuote } from 'services/snapshot-qualify'
import { SEND_QUOTE } from '../snapshot/sagas'
import actions from './actions'

const POST_QUOTE_SETUP_API = '/quote/setup'
const GET_QUALIFY_PROJECTS = '/projects?type=qualify'
const QUOTE_API = '/quote'

export function* GET_PROJECTS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(getProjects, GET_QUALIFY_PROJECTS)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        projects: success.results,
        projectsUpdatedDate: success.updatedDate,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* POST_QUALIFY_AND_SNAPSHOT_QUOTE({ payload }) {
  const qualifyData = payload.qualify
  const snapshotData = payload.snapshot

  const response = yield POST_QUOTE({ payload: qualifyData })

  snapshotData.quote.studyId = response.studyId

  yield SEND_QUOTE({ payload: snapshotData, notification: false })
}

export function* POST_QUOTE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  payload.source = 'qualify'

  const success = yield call(postQuote, payload, QUOTE_API, true)

  if (success) {
    if (payload.type === 'update' || payload.type === 'duplicate') {
      yield put({
        type: actions.UPDATE_QUOTE,
        payload: {
          quote: success,
        },
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          resetForm: true,
        },
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  return success
}

export function* GET_QUOTES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(getQuotes, `${QUOTE_API}?source=qualify`)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotes: success,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* PUT_STATUS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(putQuote, payload.data, QUOTE_API)

  if (success) {
    yield put({
      type: actions.SET_STATUS,
      payload: {
        status: payload.data.status,
        id: payload.data.id,
      },
    })

    if (payload.data.status === 'underReview')
      yield put({
        type: actions.SET_STATE,
        payload: {
          goLiveModalVisible: true,
          liveQuoteId: payload.data.id,
        },
      })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SETUP_QUOTE(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(setupQuote, payload.data, POST_QUOTE_SETUP_API)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        closeSetupModal: true,
      },
    })

    yield put({
      type: actions.SET_STATUS,
      payload: {
        status: payload.data.isDraft ? 'setupDraft' : 'setup',
        id: payload.data.id,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PROJECTS, GET_PROJECTS),
    takeEvery(actions.POST_QUOTE, POST_QUOTE),
    takeEvery(actions.POST_QUALIFY_AND_SNAPSHOT_QUOTE, POST_QUALIFY_AND_SNAPSHOT_QUOTE),
    takeEvery(actions.GET_QUOTES, GET_QUOTES),
    takeEvery(actions.PUT_STATUS, PUT_STATUS),
    takeEvery(actions.SETUP_QUOTE, SETUP_QUOTE),
  ])
}
