const actions = {
  SET_STATE: 'analytics/SET_STATE',
  POST_ANALYTICS: 'analytics/POST_ANALYTICS',
  POST_QUOTE: 'analytics/POST_QUOTE',
  UPDATE_QUOTES: 'analytics/UPDATE_QUOTES',
  GET_QUOTES: 'analytics/GET_QUOTES',
  SET_STATUS: 'analytics/SET_STATUS',
}

export default actions
