import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import actions from 'redux/home/actions'

const NavigationItem = ({ children, href }) => {
  const dispatch = useDispatch()

  return (
    <li className="ctf-nav-item">
      <Link
        className="ctf-nav-link"
        to={href}
        onClick={() => {
          switch (children.toLowerCase()) {
            case 'demo':
              dispatch({
                type: actions.SET_STATE,
                payload: { demo: true },
              })
              break
            case 'clients':
              if (document.querySelector('#clients'))
                document.querySelector('#clients').scrollIntoView({ behavior: 'smooth' })
              break
            case 'solutions':
              if (document.querySelector('#solutions'))
                document.querySelector('#solutions').scrollIntoView({ behavior: 'smooth' })
              break
            case 'contact us':
              if (document.querySelector('#quote'))
                document.querySelector('#quote').scrollIntoView({ behavior: 'smooth' })
              break
            default:
              break
          }
        }}
      >
        {children}
      </Link>
    </li>
  )
}

export default NavigationItem
