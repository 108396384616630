import { all, takeEvery, put, call } from 'redux-saga/effects'
import { get, post, getAdminViaData } from 'services/admin'
import actions from './actions'

const DELETE_URL = '/admin/delete'
const RESET_ORG_ID_URL = '/admin/resetorgid'
const GET_USERS_BY_STUDY_URL = '/admin/usersbystudy'
const DELETE_USERS_BY_ID_URL = '/admin/delete/byid'
const GET_ADMIN_VIA_DATA_URL = '/admin/via/data'

export function* DELETE_GET() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const success = yield call(get, DELETE_URL)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        users: success,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* RESET_ORG_ID({ ...data }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(post, RESET_ORG_ID_URL, data.payload)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        successResult: true,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_POST({ ...data }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(
    post,
    DELETE_URL,
    data.payload,
    "It's gone",
    'Still there something happened',
  )

  if (success) {
    yield put({
      type: actions.DELETE_USER,
      payload: {
        email: data.payload.email,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_USERS_BY_STUDY(data) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const result = yield call(get, GET_USERS_BY_STUDY_URL + data.payload.parameters, data.payload)

  if (result) {
    yield put({
      type: actions.SET_USERS_BY_STUDY,
      payload: {
        users: result,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_USERS_BY_ID({ ...data }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const result = yield call(post, DELETE_USERS_BY_ID_URL, data.payload)

  if (result) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        successResult: true,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_ADMIN_VIA_DATA({...data}){
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })

    const result = yield call(getAdminViaData,GET_ADMIN_VIA_DATA_URL, {...data})

    if (result?.statusCode === 200) {      
      yield put({
        type: actions.SET_ADMIN_VIA_DATA,
        payload: {
          adminTestData: result?.data,
        },
      })
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.DELETE_GET, DELETE_GET),
    takeEvery(actions.DELETE_POST, DELETE_POST),
    takeEvery(actions.RESET_ORG_ID, RESET_ORG_ID),
    takeEvery(actions.GET_USERS_BY_STUDY, GET_USERS_BY_STUDY),
    takeEvery(actions.DELETE_USERS_BY_ID, DELETE_USERS_BY_ID),
    takeEvery(actions.GET_ADMIN_VIA_DATA, GET_ADMIN_VIA_DATA),
  ])
}
