import actions from './actions'

const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  gender: '',
  jobFunction: '',
  country: '',
  role: 'user',
  email: '',
  companyCode: '',
  logos: [],
  terms: [],
  privacyPolicyLink: '',
  blacklists: [],
  whitelists: [],
  excludedFiles: [],
  qualifySearchDomains: [],
  authorized: false,
  loading: false,
  quoteOption: 0,
  editQuoteModalVisible: false,
  setupQuoteModalVisible: false,
  signUpData: {}, // Added to store signup data
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET_STATE:
      return { ...initialState }
    case actions.ADD_FILES: {
      const { kind, name } = action.payload
      const newFiles = [name]
      if (!newFiles.includes(name)) newFiles.push(name)
      return { ...state, [`${kind}`]: [name] }
    }
    case actions.SAVE_SIGNUP_DATA: {
      return { ...state, signUpData: action.payload }
    }
    default:
      return state
  }
}
