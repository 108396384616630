import React from 'react'
import { withRouter } from 'react-router-dom'
import { Layout } from 'antd'
import PoYHeader from 'components/PoYLayoutComponents/Home/Header/Home'
import PoYFooter from 'components/PoYLayoutComponents/Home/Footer'
import Cookie from 'components/PoYLayoutComponents/Cookie'

const { Content } = Layout

@withRouter
class PublicLayout extends React.PureComponent {
  render() {
    const { children } = this.props
    return (
      <Layout>
        <PoYHeader />
        <Content>{children}</Content>
        <PoYFooter />
        <Cookie />
      </Layout>
    )
  }
}

export default PublicLayout
