import { notification } from 'antd'
import axios from '../axios'
import userPool from '../userpool'

const VERIGLIF_CODE_API = '/account/veriglif'

export default async function uploadCode(code) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .put(
          VERIGLIF_CODE_API,
          { code: `${code}` },
          {
            headers: {
              Authorization: `Bearer ${session.idToken.jwtToken}`,
            },
          },
        )
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          localStorage.removeItem('redirected')
          notification.warning({
            message: 'Error',
            description: 'An error occurred please try again',
          })
          setTimeout(() => {
            window.location = window.location.pathname
          }, 2000)
          reject(error)
        })
    })
  })
}
