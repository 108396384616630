import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import styles from './style.module.scss'
import './style.css'

const Cookie = () => {
  const [cookie, setCookie] = useState(false)

  useEffect(() => {
    let accepted = false
    document.cookie.split(';').forEach(kookie => {
      if (kookie.split('=')[0].trim() === 'accepted') accepted = true
    })

    if (!accepted)
      setTimeout(() => {
        setCookie(true)
      }, 1000)
  }, [])

  return (
    <CSSTransition in={cookie} timeout={300} classNames="alert" unmountOnExit>
      <div className={styles.layout}>
        <ExclamationCircleOutlined className={styles.exclamation} />
        <span>
          {' '}
          We use cookies to provide you with the best user experience. You can find out how to
          manage cookies{' '}
          <a
            href="https://www.powrofyou.com/legal/cookies"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          , otherwise we’ll assume you are happy to accept them
        </span>
        <Button
          type="primary"
          className={styles.button}
          onClick={() => {
            const expiryDate = new Date()
            expiryDate.setMonth(expiryDate.getMonth() + 3)
            const cookieOptions = `accepted=true; expires=${expiryDate.toUTCString()};`
            document.cookie = cookieOptions
            setCookie(false)
          }}
        >
          OK
        </Button>
      </div>
    </CSSTransition>
  );
}

export default Cookie
