import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'antd'
import actions from 'redux/home/actions'
import NavigationItem from './NavigationItem'

const Navigation = () => {
  const demo = useSelector((state) => state.home.demo)

  const dispatch = useDispatch()

  const handleDemoCancel = () => {
    dispatch({
      type: actions.SET_STATE,
      payload: { demo: false },
    })
  }

  return (
    <>
      <Modal open={demo} onCancel={handleDemoCancel} footer={null}>
        <iframe
          title="demo"
          src="https://forms.clickup.com/1287587/f/179d3-617/M218512E46EREFHANB"
          style={{ width: '102%', height: '100%', minHeight: 570, marginLeft: '0%' }}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading...
        </iframe>
      </Modal>

      <nav className="ctf-nav">
        <Link className="ctf-nav-logo" to="/">
          <img
            className="ctf-nav-logo-img-light"
            src="resources/images/pyi-logo.png"
            alt="Powr of you"
          />
        </Link>
        <ul className="ctf-nav-list">
          <NavigationItem href="/">Solutions</NavigationItem>
          <NavigationItem href="/">Clients</NavigationItem>
          <NavigationItem href="/demo">Demo</NavigationItem>
          <NavigationItem href="/login">Log in</NavigationItem>
          <NavigationItem href="/signup">Sign up</NavigationItem>
          <NavigationItem href="/">Contact us</NavigationItem>
        </ul>
        <button
          type="button"
          className="ctf-nav-toggle"
          onClick={() => {
            document.querySelector('.ctf-nav-toggle').classList.toggle('ctf-nav-toggle-open')
            document.querySelector('.ctf-nav-list').classList.toggle('ctf-nav-list-open')
          }}
        >
          <span>Toggle Menu</span>
        </button>
      </nav>
    </>
  )
}

export default Navigation
