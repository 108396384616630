import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Home page
  {
    path: '/',
    component: loadable(() => import('pages/home')),
    exact: true,
  },
  // Home Pages
  {
    path: '/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/signup',
    component: loadable(() => import('pages/user/signup')),
    exact: true,
  },
  {
    path: '/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Account pages
  // Instants
  {
    path: '/account/instants',
    component: loadable(() => import('pages/account/instants')),
    exact: true,
  },
  {
    path: '/account/instants/myquotes',
    component: loadable(() => import('pages/account/instants/myquotes')),
    exact: true,
  },

  //Via
  {
    path: '/account/via',
    component: loadable(() => import('pages/account/via')),
    exact: true,
  },

  // New quote
  {
    path: '/account/newquote',
    component: loadable(() => import('pages/account/newquote')),
    exact: true,
  },

  // Analytics
  {
    path: '/account/analytics/myquotes',
    component: loadable(() => import('pages/account/analytics/myquotes')),
    exact: true,
  },

  // Doc hub
  {
    path: '/account/dochub',
    component: loadable(() => import('pages/account/dochub')),
    exact: true,
  },

  // Demo
  {
    path: '/account/demo/product',
    component: loadable(() => import('pages/account/demo/product')),
    exact: true,
  },
  {
    path: '/account/demo/casestudies',
    component: loadable(() => import('pages/account/demo/casestudies')),
    exact: true,
  },

  // Veriglif
  {
    path: '/account/veriglif',
    component: loadable(() => import('pages/account/veriglif')),
    exact: true,
  },
  {
    path: '/account/veriglif/newstudy',
    component: loadable(() => import('pages/account/veriglif/newstudy')),
    exact: true,
  },
  {
    path: '/account/veriglif/connect',
    component: loadable(() => import('pages/account/veriglif/connect')),
    exact: true,
  },
  {
    path: '/account/profile',
    component: loadable(() => import('pages/account/profile')),
    exact: true,
  },

  // Admin
  {
    path: '/admin/approvals',
    component: loadable(() => import('pages/admin/approvals')),
    exact: true,
  },
  {
    path: '/admin/deleteuser',
    component: loadable(() => import('pages/admin/deleteuser')),
    exact: true,
  },
  {
    path: '/admin/adduser',
    component: loadable(() => import('pages/admin/adduser')),
    exact: true,
  },
  {
    path: '/admin/assignstudy',
    component: loadable(() => import('pages/admin/assignstudy')),
    exact: true,
  },
  {
    path: '/admin/removeuser',
    component: loadable(() => import('pages/admin/removeuser')),
    exact: true,
  },
  {
    path: '/admin/resetstudystats',
    component: loadable(() => import('pages/admin/resetstudystats')),
    exact: true,
  },

  // legal
  {
    path: '/legal',
    component: loadable(() => import('pages/legal')),
    exact: true,
  },
  {
    path: '/legal/terms',
    component: loadable(() => import('pages/legal/terms')),
    exact: true,
  },
  {
    path: '/legal/terms/t_20200526',
    component: loadable(() => import('pages/legal/terms/t_20200526')),
    exact: true,
  },
  {
    path: '/legal/cookies',
    component: loadable(() => import('pages/legal/cookies')),
    exact: true,
  },
  {
    path: '/legal/survey',
    component: loadable(() => import('pages/legal/survey')),
    exact: true,
  },
  {
    path: '/legal/privacy',
    component: loadable(() => import('pages/legal/privacy')),
    exact: true,
  },
  {
    path: '/legal/usprivacy',
    component: loadable(() => import('pages/legal/usprivacy')),
    exact: true,
  },
  {
    path: '/legal/privacy/p_20200701',
    component: loadable(() => import('pages/legal/privacy/p_20200701')),
    exact: true,
  },
  {
    path: '/legal/acceptableUse',
    component: loadable(() => import('pages/legal/acceptableUse')),
    exact: true,
  },
  {
    path: '/legal/attributions',
    component: loadable(() => import('pages/legal/attributions')),
    exact: true,
  },
  {
    path: '/legal/subprocessors',
    component: loadable(() => import('pages/legal/subprocessors')),
    exact: true,
  },
   // Test Data
   {
    path: '/account/testdata',
    component: loadable(() => import('pages/account/testdata')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            {routes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
