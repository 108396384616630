const actions = {
  SET_STATE: 'admin/SET_STATE',
  DELETE_USER: 'admin/DELETE_USER',
  DELETE_GET: 'admin/DELETE_GET',
  DELETE_POST: 'admin/DELETE_POST',
  RESET_ORG_ID: 'admin/RESET_ORG_ID',
  GET_USERS_BY_STUDY: 'admin/GET_USERS_BY_STUDY',
  SET_USERS_BY_STUDY: 'admin/SET_USERS_BY_STUDY',
  DELETE_USERS_BY_ID: 'admin/DELETE_USERS_BY_ID',
  GET_ADMIN_VIA_DATA:  'admin/GET_ADMIN_VIA_DATA',
  SET_ADMIN_VIA_DATA:  'admin/SET_ADMIN_VIA_DATA',
}

export default actions
