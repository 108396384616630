import actions from './actions'

const initialState = {
  loading: false,
  categories: [],
  urls: [],
  categoriesRequestId: '',
  newQuoteModalVisible: false,
  completeModalVisible: false,
  quotes: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.UPDATE_QUOTES: {
      const newQuotes = [...state.quotes]
      const quoteIndexToReplace = newQuotes.findIndex(quote => quote.id === action.payload.quote.id)

      if (quoteIndexToReplace === -1) {
        newQuotes.push(action.payload.quote)
      } else {
        newQuotes[quoteIndexToReplace] = action.payload.quote
      }

      return { ...state, quotes: newQuotes }
    }
    case actions.SET_STATUS: {
      const newQuotes = [...state.quotes]
      const quoteIndexToReplace = newQuotes.findIndex(quote => quote.id === action.payload.id)

      newQuotes[quoteIndexToReplace].status = action.payload.status

      return { ...state, closeSetupModal: true, quotes: newQuotes }
    }
    default:
      return state
  }
}
