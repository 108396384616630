const actions = {
  SET_CURRENCY: 'snapshot/SET_CURRENCY',
  SET_DATA_COST: 'snapshot/SET_DATA_COST',
  SET_ANALYTICS_COST: 'snapshot/SET_ANALYTICS_COST',
  SEND_QUOTE: 'snapshot/SEND_QUOTE',
  SET_STATE: 'snapshot/SET_STATE',
  GET_QUOTES: 'snapshot/GET_QUOTES',
  UPDATE_QUOTE: 'snapshot/UPDATE_QUOTE',
  SETUP_QUOTE: 'snapshot/SETUP_QUOTE',
  SET_STATUS: 'snapshot/SET_STATUS',
  PUT_STATUS: 'snapshot/PUT_STATUS',
  GET_PROJECTS: 'snapshot/GET_PROJECTS',
  COMPLETE_PROJECT: 'snapshot/GET_PROJECTS',
  RESET_STATE: 'veriglif/RESET_STATE',
  GET_ASSIGNED_TO_PROJECT: 'snapshot/GET_ASSIGNED_TO_PROJECT',
  ASSIGN_TO_PROJECT: 'snapshot/ASSIGN_TO_PROJECT',
  DELETE_ASSIGN_FROM_PROJECT: 'snapshot/DELETE_ASSIGN_FROM_PROJECT',
  UPDATE_ASSIGNED_TO_PROJECT: 'snapshot/UPDATE_ASSIGNED_TO_PROJECT',
  RECRUITMENT_DONE: 'snapshot/RECRUITMENT_DONE',
}

export default actions
