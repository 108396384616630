import actions from './actions'

const initialState = {
  authorized: true,
  summaryData: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_SUMMARY_DATA: {
      const summaryData = { ...state.summaryData, ...action.payload }
      return { ...state, summaryData }
    }
    case actions.DELETE_SUMMARY_DATA: {
      const newData = state.summaryData[action.payload.type].values.filter(
        d => d.id !== action.payload.id && action.payload.id !== -1,
      )
      const newSummaryData = { ...state.summaryData }

      if (newData.length) {
        newSummaryData[action.payload.type].values = newData
      } else {
        delete newSummaryData[action.payload.type]
      }

      return { ...state, summaryData: { ...newSummaryData } }
    }
    case actions.RESET_STATE:
      return { ...initialState }
    default:
      return state
  }
}
