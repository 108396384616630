import React from 'react'
import { withRouter } from 'react-router-dom'
import { BackTop, Layout } from 'antd'
// import PoYHeader from 'components/Home/Header/Home'
import LegalMenuTop from 'components/LayoutComponents/Menu/LegalMenuTop'

const { Content } = Layout

@withRouter
class GuestLayout extends React.PureComponent {
  render() {
    const { children } = this.props
    return (
      <Layout>
        <BackTop />
        <LegalMenuTop />
        <Content>{children}</Content>
      </Layout>
    )
  }
}

export default GuestLayout
