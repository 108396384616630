const actions = {
  SET_CURRENCY: 'instants/SET_CURRENCY',
  SET_DATA_COST: 'instants/SET_DATA_COST',
  SET_ANALYTICS_COST: 'instants/SET_ANALYTICS_COST',
  SEND_QUOTE: 'instants/SEND_QUOTE',
  SET_STATE: 'instants/SET_STATE',
  GET_QUOTES: 'instants/GET_QUOTES',
  UPDATE_QUOTE: 'instants/UPDATE_QUOTE',
  SETUP_QUOTE: 'instants/SETUP_QUOTE',
  SET_STATUS: 'instants/SET_STATUS',
  PUT_STATUS: 'instants/PUT_STATUS',
  GET_STUDIES: 'instants/GET_STUDIES',
  COMPLETE_PROJECT: 'instants/GET_PROJECTS',
  RESET_STATE: 'veriglif/RESET_STATE',
  GET_ASSIGNED_TO_PROJECT: 'instants/GET_ASSIGNED_TO_PROJECT',
  ASSIGN_TO_PROJECT: 'instants/ASSIGN_TO_PROJECT',
  DELETE_ASSIGN_FROM_PROJECT: 'instants/DELETE_ASSIGN_FROM_PROJECT',
  UPDATE_ASSIGNED_TO_PROJECT: 'instants/UPDATE_ASSIGNED_TO_PROJECT',
  RECRUITMENT_DONE: 'instants/RECRUITMENT_DONE',
  POST_QUALIFY_AND_SNAPSHOT_QUOTE: 'POST_QUALIFY_AND_SNAPSHOT_QUOTE',
}

export default actions
