import React, { useState } from 'react';
import { Dropdown, Avatar, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import styles from './style.module.scss';

const LanguageMenu = () => {
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');

  const handleLanguageChange = (langCode) => {
    localStorage.setItem('lng', langCode);
    setLanguage(langCode)
    window.location.reload();
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item className={language.startsWith('en') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('en_US')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/us.svg" className={`${styles.menuIcon}`} alt="en" />
          <span>EN</span>
        </div>
      </Menu.Item>
      <Menu.Item className={language.startsWith('de') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('de')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/de.svg" className={`${styles.menuIcon}`} alt="de" />
          <span>DE</span>
        </div>
      </Menu.Item>
      <Menu.Item className={language.startsWith('th') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('th_TH')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/th.svg" className={`${styles.menuIcon}`} alt="th" />
          <span>TH</span>
        </div>
      </Menu.Item>
      <Menu.Item className={language.startsWith('id') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('id')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/id.svg" className={`${styles.menuIcon}`} alt="id" />
          <span>ID</span>
        </div>
      </Menu.Item>
      <Menu.Item className={language.startsWith('zh') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('zh-TW')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/cn.svg" className={`${styles.menuIcon}`} alt="zh" />
          <span>CN</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
        <Avatar className={styles.avatar} shape="square" size="large" icon={<GlobalOutlined />} />
    </Dropdown>
  );
};

export default LanguageMenu;
