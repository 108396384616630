import actions from './actions'

const initialState = {
  users: [],
  successResult: false,
  loading: false,
  usersByStudy: {},
  adminTestData:[]
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.DELETE_USER: {
      const users = [...state.users]

      const newUsers = users.filter((user) => user.email !== action.payload.email)
      return { ...state, users: newUsers }
    }
    case actions.SET_USERS_BY_STUDY: {
      const usersByStudy = state.usersByStudy

      const studyId = Object.keys(action.payload.users)[0]

      usersByStudy[studyId] = action.payload.users[studyId]

      return { ...state, usersByStudy }
    }
    case actions.SET_ADMIN_VIA_DATA: {
      return {
        ...state,
        adminTestData: action.payload.adminTestData,
      };
    }
    default:
      return state
  }
}
