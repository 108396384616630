import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'components/LayoutComponents/Loader'
import { ADMIN, DEMO } from 'util/strings'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import LegalLayout from './Legal'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  legal: LegalLayout,
  main: MainLayout,
}

const allowedDemoPaths = ['/account/profile']

@withRouter
@connect(({ user, veriglif }) => ({ user, veriglif }))
class IndexLayout extends React.PureComponent {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props
    const {
      location: { pathname: prevPathname },
    } = prevProps
    if (pathname !== prevPathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
      veriglif,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search

    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public'
      }

      if (/^\/(legal)(?=\/|$)/i.test(pathname)) {
        return 'legal'
      }

      if (/^\/(login|signup|forgot)(?=\/|$)/i.test(pathname)) {
        return 'login'
      }

      return 'main'
    }

    const { role } = user
    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isLegal = getLayout() === 'legal'
    const isLoginLayout =
      getLayout() === 'public' || getLayout() === 'login' || getLayout() === 'legal'
    const isVeriglifAuthorized = veriglif.authorized

    // redirect based on roles
    if (role !== ADMIN && currentPath.startsWith('/admin/')) {
      return <Redirect to="/" />
    }

    if (
      role === DEMO &&
      !(currentPath.startsWith('/account/demo') || allowedDemoPaths.includes(currentPath))
    ) {
      return <Redirect to="/account/demo/product" />
    }

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
        return <Loader />
      }

      if (isLegal) {
        return <Container>{children}</Container>
      }

      // redirect to login page if current is not login page and user not authorized
      if (!isLoginLayout && !isUserAuthorized) {
        return <Redirect to="/" />
      }

      // redirect if veriglif is not authorized
      if (
        !isVeriglifAuthorized &&
        (currentPath === '/account/veriglif' || currentPath === '/account/veriglif/newstudy')
      ) {
        return <Redirect to="/account/veriglif/connect" />
      }

      // Redirect to veriglif dashboard if is Authorized
      if (isVeriglifAuthorized && currentPath === '/account/veriglif/connect') {
        return <Redirect to="/account/veriglif" />
      }

      // redirect to main dashboard when user on login page and authorized
      if (isLoginLayout && isUserAuthorized) {
        return <Redirect to="/account/instants" />
      }
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <>
        <Helmet titleTemplate="PY Insights | %s" title="" />
        {BootstrappedLayout()}
      </>
    )
  }
}

export default IndexLayout
