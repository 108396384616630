import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar } from 'antd'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 7,
    }
  }

  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  render() {
    const { user } = this.props
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>Hello, {user.firstName || 'Anonymous'}</strong>
          <div>
            <strong>Role: </strong>
            {user.role}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            <strong>Email: </strong>
            {user.email}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to="/account/profile">
            <i className={`${styles.menuIcon} icmn-user`} />
            Edit Profile
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="/" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            Logout
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={this.addCount}
        placement="bottomRight"
      >
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="default" icon={<UserOutlined />} />
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
