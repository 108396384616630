import React from 'react'
import { Link } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import actions from 'redux/home/actions'

import Navigation from '../Navigation'

const Header = () => {
  const dispatch = useDispatch()
  return (
    <header className="ctf-header">
      <div className="ctf-header-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="ctf-header-nav">
                <div className="ctf-nav-light">
                  <Navigation />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h1 className="ctf-header-title">Understand the digital consumer with data</h1>
              <div className="ctf-header-title-note">
                <p>
                  PY Insights gives you direct access to actual behavioral data and insights with
                  consent, using any panel or audience.
                </p>
                <br />
                <p>
                  Tap into a consumer’s Browser Clickstream, Search Activity, Mobile App Usage,
                  Shopping Basket and more with consent.
                </p>
              </div>
              <div className="ctf-header-buttons">
                <Link
                  className="ctf-button ctf-button-purple ctf-button-purple-bordered mr-4"
                  to=""
                  onClick={() => {
                    dispatch({
                      type: actions.SET_STATE,
                      payload: { demo: true },
                    })
                  }}
                >
                  REQUEST DEMO ACCESS
                </Link>
                <Link
                  to="/"
                  className="ctf-button ctf-button-white"
                  onClick={() =>
                    document.querySelector('#solutions').scrollIntoView({ behavior: 'smooth' })
                  }
                >
                  LEARN MORE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
