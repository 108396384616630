import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getProjects, refreshViaDetails, dailyDataFeed } from 'services/snapshot-qualify'
import actions from './actions'

const GET_VIA_PROJECTS = 'via/projects'
const POST_REFRESH_DETAILS = 'via/projects/details'

export function* GET_PROJECTS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(getProjects, GET_VIA_PROJECTS)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        projects: success.results,
        projectsUpdatedDate: success.updatedDate,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* REFRESH_DETAILS() {
  const success = yield call(refreshViaDetails, POST_REFRESH_DETAILS)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        refresh: true,
      },
    })
  }
}

export function* DAILY_DATA_FEED({ payload }) {
  const success = yield call(dailyDataFeed, {...payload} )

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        refresh: true,
      },
    })
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_PROJECTS, GET_PROJECTS),
    takeEvery(actions.REFRESH_DETAILS, REFRESH_DETAILS),
    takeEvery(actions.DAILY_DATA_FEED, DAILY_DATA_FEED),
  ])
}
