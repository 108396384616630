import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  postQuote,
  getQuotes,
  setupQuote,
  putQuote,
  getProjects,
  markProjectComplete,
  getAssignedToProject,
  postAssignToProject,
  postDeleteAssignFromProject,
  recruitmentDone,
} from 'services/snapshot-qualify'
import actions from './actions'

const POST_QUOTE_SETUP_API = '/quote/setup'
const GET_SNAPSHOT_PROJECTS = '/projects?type=snapshot'
const QUOTE_API = '/quote'

export function* SEND_QUOTE({ payload, notification = true }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  payload.source = 'snapshot'

  const success = yield call(postQuote, payload, QUOTE_API, notification)

  if (success) {
    if (payload.type === 'update' || payload.type === 'duplicate') {
      yield put({
        type: actions.UPDATE_QUOTE,
        payload: {
          quote: success,
        },
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          resetForm: true,
        },
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_QUOTES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(getQuotes, `${QUOTE_API}?source=snapshot`)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotes: success,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SETUP_QUOTE(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(setupQuote, payload.data, POST_QUOTE_SETUP_API)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        closeSetupModal: true,
      },
    })
    yield put({
      type: actions.SET_STATUS,
      payload: {
        status: payload.data.isDraft ? 'setupDraft' : 'setup',
        id: payload.data.id,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* PUT_STATUS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(putQuote, payload.data, QUOTE_API)

  if (success) {
    yield put({
      type: actions.SET_STATUS,
      payload: {
        status: payload.data.status,
        id: payload.data.id,
      },
    })

    if (payload.data.status === 'underReview')
      yield put({
        type: actions.SET_STATE,
        payload: {
          goLiveModalVisible: true,
          liveQuoteId: payload.data.id,
        },
      })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_PROJECTS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const success = yield call(getProjects, GET_SNAPSHOT_PROJECTS)

  if (success) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        projects: success.results,
        projectsUpdatedDate: success.updatedDate,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_ASSIGNED_TO_PROJECT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const result = yield call(getAssignedToProject, payload.orgId, payload.type)

  if (result) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        assigned: result,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* ASSIGN_TO_PROJECT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const result = yield call(postAssignToProject, payload)

  if (result) {
    yield put({
      type: actions.UPDATE_ASSIGNED_TO_PROJECT,
      payload: {
        email: payload.email,
        type: 'add',
      },
    })

    if (payload.action === 'admin') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          successResult: true,
        },
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_ASSIGN_FROM_PROJECT({ payload }) {
  const result = yield call(postDeleteAssignFromProject, payload)

  if (result) {
    yield put({
      type: actions.UPDATE_ASSIGNED_TO_PROJECT,
      payload: {
        email: payload.email,
      },
    })

    if (payload.action === 'admin') {
      yield put({
        type: actions.SET_STATE,
        payload: {
          successResult: true,
        },
      })
    }
  }
}

export function* COMPLETE_PROJECT({ payload }) {
  if (payload) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })

    const success = yield call(markProjectComplete, { ...payload })

    if (success) {
      yield put({
        type: actions.GET_PROJECTS,
      })

      window.location.reload()
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* RECRUITMENT_DONE({ payload }) {
  if (payload) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })

    yield call(recruitmentDone, { ...payload })

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEND_QUOTE, SEND_QUOTE),
    takeEvery(actions.GET_QUOTES, GET_QUOTES),
    takeEvery(actions.SETUP_QUOTE, SETUP_QUOTE),
    takeEvery(actions.PUT_STATUS, PUT_STATUS),
    takeEvery(actions.GET_PROJECTS, GET_PROJECTS),
    takeEvery(actions.COMPLETE_PROJECT, COMPLETE_PROJECT),
    takeEvery(actions.GET_ASSIGNED_TO_PROJECT, GET_ASSIGNED_TO_PROJECT),
    takeEvery(actions.ASSIGN_TO_PROJECT, ASSIGN_TO_PROJECT),
    takeEvery(actions.DELETE_ASSIGN_FROM_PROJECT, DELETE_ASSIGN_FROM_PROJECT),
    takeEvery(actions.RECRUITMENT_DONE, RECRUITMENT_DONE),
  ])
}
