import { all } from 'redux-saga/effects'
import user from './user/sagas'
import admin from './admin/sagas'
import menu from './menu/sagas'
import veriglif from './veriglif/sagas'
import settings from './settings/sagas'
import snapshot from './snapshot/sagas'
import qualify from './qualify/sagas'
import via from './via/sagas'
import analytics from './analytics/sagas'
import instants from './instants/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    admin(),
    menu(),
    settings(),
    veriglif(),
    snapshot(),
    qualify(),
    via(),
    analytics(),
    instants(),
  ])
}
