import { notification } from 'antd'
import axios from '../axios'
import userPool from '../userpool'

const QUOTES = '/analytics/quote'

const DEFAULT_ERROR = 'An error occurred please try again'
const DEFAULT_ERROR_TITLE = 'Error'

export async function postAnalytics({ ...data }) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          data.url,
          { ...data.values },
          {
            headers: {
              Authorization: `Bearer ${session.idToken.jwtToken}`,
            },
          },
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          notification.warning({
            message: DEFAULT_ERROR_TITLE,
            description: DEFAULT_ERROR,
          })
          resolve(false)
        })
    })
  })
}

export async function postQuote({ ...data }) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          QUOTES,
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${session.idToken.jwtToken}`,
            },
          },
        )
        .then((response) => {
          notification.success({
            message: 'All done!',
          })
          resolve(response?.data)
        })
        .catch(() => {
          notification.warning({
            message: DEFAULT_ERROR_TITLE,
            description: DEFAULT_ERROR,
          })
          resolve(false)
        })
    })
  })
}

export async function getQuotes() {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .get(QUOTES, {
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          notification.warning({
            message: DEFAULT_ERROR_TITLE,
            description: DEFAULT_ERROR,
          })
          resolve(false)
        })
    })
  })
}
