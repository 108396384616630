const actions = {
  SET_STATE: 'qualify/SET_STATE',
  GET_PROJECTS: 'qualify/GET_PROJECTS',
  SET_CURRENCY: 'qualify/SET_CURRENCY',
  POST_QUOTE: 'qualify/POST_QUOTE',
  POST_QUALIFY_AND_SNAPSHOT_QUOTE: 'qualify/POST_QUALIFY_AND_SNAPSHOT_QUOTE',
  GET_QUOTES: 'qualify/GET_QUOTES',
  UPDATE_QUOTE: 'qualify/UPDATE_QUOTE',
  PUT_STATUS: 'qualify/PUT_STATUS',
  SET_STATUS: 'qualify/SET_STATUS',
  SETUP_QUOTE: 'qualify/SETUP_QUOTE',
}

export default actions
