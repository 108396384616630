import React from 'react'
import Navigation from '../Navigation'

const Header = ({ children }) => (
  <header className="ctf-header" style={{ marginBottom: 0 }}>
    <div className="ctf-header-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="ctf-header-nav">
              <div className="ctf-nav-light">
                <Navigation />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">{children}</div>
        </div>
      </div>
    </div>
  </header>
)

export default Header
