const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  SIGNUP: 'user/SIGNUP',
  ADD_FILES: 'user/ADD_FILES',
  PUT_PROFILE: 'user/PUT_PROFILE',
  RESET_STATE: 'user/RESET_STATE',
  SAVE_SIGNUP_DATA : 'user/SAVE_SIGNUP_DATA',
}

export default actions
