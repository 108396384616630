import { notification } from 'antd'
import axios from '../axios'
import userPool from '../userpool'

export async function get(url) {
  const cognitoUser = userPool.getCurrentUser()
  function getCurrentUser() {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err)
        }
        axios
          .get(url, {
            headers: {
              Authorization: `${session.idToken.jwtToken}`,
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            resolve(false)
          })
      })
    })
  }
  if (cognitoUser != null) return getCurrentUser()

  return false
}

export async function post(url, payload, successMessage = 'Success', errorMessage = 'Error') {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          url,
          { ...payload },
          {
            headers: {
              Authorization: session.idToken.jwtToken,
            },
          },
        )
        .then(() => {
          notification.success({ message: successMessage })
          resolve(true)
        })
        .catch((err) => {
          notification.error({ message: err?.response?.data || errorMessage })
          resolve(false)
        })
    })
  })
}

export async function getAdminViaData(url,data) {
  const cognitoUser = userPool.getCurrentUser()
  function getCurrentUser() {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err)
        }
        axios
          .get(url, {
            headers: {
              Authorization: `${session.idToken.jwtToken}`,
            },
            params: data.payload, // Send data as query parameters
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            resolve(false)
          })
      })
    })
  }
  if (cognitoUser != null) return getCurrentUser()

  return false
}