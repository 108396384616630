import { notification } from 'antd'
import axios from '../axios'
import userPool from '../userpool'

const COMPLETE_PROJECT_URL = '/projects/markCompleted'
const RECRUITMENT_DONE_URL = '/projects/recruitmentDone'
const ASSIGN_TO_PROJECT_URL = '/projects/assign'
const DELETE_ASSIGN_FROM_PROJECT_URL = '/projects/assign/delete'
const POST_DAILY_DATA_FEED = '/via/daily/data/feed'

export async function postQuote(data, url, showNotification = true) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          url,
          { data },
          {
            headers: {
              Authorization: `Bearer ${session.idToken.jwtToken}`,
            },
          },
        )
        .then((response) => {
          if (data.type === 'update' && showNotification) {
            notification.success({
              message: 'Quote was updated successfully',
            })
          }
          resolve(response.data)
        })
        .catch(() => {
          notification.info({
            message: 'Error',
            description: 'An error occurred please try again',
          })
          resolve(false)
        })
    })
  })
}

export async function markProjectComplete(data) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          COMPLETE_PROJECT_URL,
          { data },
          {
            headers: {
              Authorization: `Bearer ${session.idToken.jwtToken}`,
            },
          },
        )
        .then((response) => {
          notification.success({
            message: 'Study was marked successfully',
          })
          resolve(response.data)
        })
        .catch((error) => {
          notification.warning({
            message: error.response.data,
          })
          resolve(false)
        })
    })
  })
}

export async function recruitmentDone(data) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          RECRUITMENT_DONE_URL,
          { data },
          {
            headers: {
              Authorization: `Bearer ${session.idToken.jwtToken}`,
            },
          },
        )
        .then((response) => {
          notification.success({
            message: 'Study will move forward with analytics',
          })
          resolve(response.data)
        })
        .catch(() => {
          notification.warning({
            message: 'Error',
            description: 'An error occurred please try again',
          })
          resolve(false)
        })
    })
  })
}

export async function getQuotes(url) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${session.idToken.jwtToken}`,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch(() => {
          notification.warning({
            message: 'Error',
            description: 'An error occurred please try again',
          })
          resolve(false)
        })
    })
  })
}

export async function setupQuote(setup, url) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          url,
          { ...setup },
          {
            headers: {
              Authorization: session.idToken.jwtToken,
            },
          },
        )
        .then(() => {
          notification.success({
            message: 'Study setup details saved successfully',
          })
          resolve(true)
        })
        .catch((error) => {
          notification.warning({
            message: 'Error',
            description: error.message,
          })
          resolve(false)
        })
    })
  })
}

export async function putQuote(payload, url) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .put(
          url,
          { data: payload },
          {
            headers: {
              Authorization: session.idToken.jwtToken,
            },
          },
        )
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          notification.warning({
            message: 'Error',
            description: error?.response?.data,
            duration: 300,
          })
          resolve(false)
        })
    })
  })
}

export async function getProjects(url) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .get(url, {
          headers: {
            Authorization: session.idToken.jwtToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          notification.warning({
            message: 'Error',
            description: error.message,
          })
          resolve(false)
        })
    })
  })
}

export async function refreshViaDetails(url) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          url,
          {},
          {
            headers: {
              Authorization: session.idToken.jwtToken,
            },
          },
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          notification.warning({
            message: 'Error',
            description: error.message,
          })
          resolve(false)
        })
    })
  })
}


export async function dailyDataFeed(data) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          POST_DAILY_DATA_FEED,
          { data },
          {
            headers: {
              Authorization: `Bearer ${session.idToken.jwtToken}`,
            },
          },
        )
        .then((response) => {
          resolve(response.data)
          notification.info({
            message: 'Success',
            description: response?.data?.body,
          })
        })
        .catch((error) => {
          if (error.code === 'ECONNABORTED') {
            console.error('Request timed out');
          } else {
            console.error('Error:', error.message);
          }
          notification.info({
            message: 'Error',
            description: 'An error occurred please try again',
          })
          resolve(false)
        })
    })
  })
}


export async function getAssignedToProject(orgId, type) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .get(`${ASSIGN_TO_PROJECT_URL}?orgId=${orgId}&type=${type}`, {
          headers: {
            Authorization: session.idToken.jwtToken,
          },
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          notification.warning({
            message: error.response.data,
          })
          resolve(false)
        })
    })
  })
}

export async function postAssignToProject(payload) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          ASSIGN_TO_PROJECT_URL,
          { ...payload },
          {
            headers: {
              Authorization: session.idToken.jwtToken,
            },
          },
        )
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          notification.error({
            message: error.response.data,
          })
          resolve(false)
        })
    })
  })
}

export async function postDeleteAssignFromProject(payload) {
  const cognitoUser = userPool.getCurrentUser()
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
      }
      axios
        .post(
          DELETE_ASSIGN_FROM_PROJECT_URL,
          { ...payload },
          {
            headers: {
              Authorization: session.idToken.jwtToken,
            },
          },
        )
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          notification.error({
            message: 'Error',
            description: error.response.data,
          })
          resolve(false)
        })
    })
  })
}
