export const BROWSER = 'browser'
export const BROWSER_MARKETS = 'browserMarkets'
export const ANDROID = 'android'
export const ANDROID_MARKETS = 'androidMarkets'
export const ANALYTICS_REFERENCE_EXPLANATION =
  'Unique identifier for each request, specified by Client, to track requests and responses (results). It will be returned in response'
export const ANALYTICS_NEW_QUOTE_TITLE = 'New quote'
export const UPDATE = 'update'
export const QUALIFY_SEARCH_DOMAINS = 'qualifySearchDomains'
export const FILE_UPLOAD_SUCCESS = 'File uploaded successfully.'
export const FILE_UPLOAD_FAIL = 'File upload failed.'
export const DEFAULT_ERROR_MESSAGE = 'Field is required'
export const SNAPSHOT_LOWER = 'snapshot'
export const DESKTOP_SNAPSHOT = 'desktopSnapshot'
export const ANDROID_SNAPSHOT = 'androidSnapshot'
export const DESKTOP_QUALIFY = 'desktopQualify'
export const ANDROID_QUALIFY = 'androidQualify'
export const DESKTOP_QUALIFY_SNAPSHOT = 'desktopQualifySnapshot'
export const ANDROID_QUALIFY_SNAPSHOT = 'androidQualifySnapshot'
export const ANALYTICS = 'analytics'
export const VIA_ANALYTICS = 'viaAnalytics'
export const DESKTOP_UI = 'desktopUI'
export const ANDROID_UI = 'androidUI'
export const SNAPSHOT_SETUP = 'snapshotSetup'
export const QUALIFY_SETUP = 'qualifySetup'
export const ADMIN = 'admin'
export const DEMO = 'demo'
export const FINISH_EDITING_MESSAGE = 'Please finish editing quote for info to appear'
export const FINISH_EDITING_MESSAGE_QUALIFY =
  'Please finish editing quote (including Snapshot if required) for info to appear'
export const CUSTOM = 'Custom'
export const EMAIL_VALID_PROMPT = 'Please enter a valid email'
export const UNKNOWN_TBD = 'Unknown - TBD'
export const VIA_DETAILS_TAB_SIGNED_UP_TYPE = 'signed_up'
export const VIA_DETAILS_TAB_WITH_DATA_TYPE = 'with_data'
export const VIA_DETAILS_TAB_DAILY_DATA_TYPE = 'daily_data'
export const VIA_DETAILS_TAB_MISSIONS_TYPE = 'missions'
