import React from 'react'
import { withRouter } from 'react-router-dom'
import { Layout } from 'antd'
import PoYHeader from 'components/PoYLayoutComponents/Home/Header/Login'
import PoYFooter from 'components/PoYLayoutComponents/Home/Footer'
import Cookie from 'components/PoYLayoutComponents/Cookie'

@withRouter
class LoginLayout extends React.PureComponent {
  render() {
    const { children } = this.props
    return (
      <Layout>
        <PoYHeader>{children}</PoYHeader>
        <PoYFooter />
        <Cookie />
      </Layout>
    )
  }
}

export default LoginLayout
