import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => (
  <footer className="ctf-footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="ctf-footer-container">
            <p className="ctf-footer-copyright">
              <img
                className="ctf-footer-logo"
                src="resources/images/pyi-logo-home.png"
                alt="Powr of You logo"
              />
              <span className="ctf-footer-copy-text">
                Copyright © 2014 - {new Date().getFullYear()}
                <br /> Powr of You® Ltd. |{' '}
                <Link className="ctf-footer-link" to="legal">
                  Privacy Policy
                </Link>
              </span>
            </p>
            <div className="ctf-footer-contacts-wrapper">
              <div className="ctf-footer-contacts">
                <p className="ctf-footer-contacts-item" style={{ marginLeft: 0 }}>
                  <b>United States</b>, San Francisco
                  <br />
                  <a className="ctf-footer-link" href="tel:+19254875326">
                    +1 925 478 5670
                  </a>
                </p>
                <p className="ctf-footer-contacts-item">
                  <b>United Kingdom</b>, London
                  <br />
                  <a className="ctf-footer-link" href="tel:+447449022218">
                    +44 74490 22218
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
