import { all, takeEvery, put, call } from 'redux-saga/effects'
import { postAnalytics, postQuote, getQuotes } from 'services/analytics'
import actions from './actions'

export function* POST_ANALYTICS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  if (payload) {
    const response = yield call(postAnalytics, payload)

    if (response) {
      if (payload.url.includes('categorize')) {
        export2json(response, 'categorize')
        yield put({
          type: actions.SET_STATE,
          payload: {
            categories: response.categories,
            categoriesRequestId: response.requestId,
          },
        })
      } else {
        export2json(response, 'search')
        yield put({
          type: actions.SET_STATE,
          payload: {
            urls: response.SearchTerm,
          },
        })
      }
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

const export2json = (data, name) => {
  const parseData= data ? JSON.parse(data) : null;
  const a = document.createElement('a')
  a.href = URL.createObjectURL(
    new Blob([JSON.stringify(data, null, 2)], {
      type: 'text/plain',
    }),
  )
  a.setAttribute('download', `${parseData?.requestId}_${name}.json`)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export function* POST_QUOTE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  if (payload) {
    const response = yield call(postQuote, payload)

    if (response) {
      if (payload.type === 'archive') {
        yield put({
          type: actions.SET_STATUS,
          payload: {
            id: payload.values.id,
            status: 'archive',
          },
        })
      } else {
        payload.values.id = response.id
        payload.values.status = 'quote'
        yield put({
          type: actions.UPDATE_QUOTES,
          payload: {
            quote: payload.values,
          },
        })
      }
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      newQuoteModalVisible: false,
    },
  })
}

export function* GET_QUOTES() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getQuotes)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotes: response,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.POST_ANALYTICS, POST_ANALYTICS),
    takeEvery(actions.POST_QUOTE, POST_QUOTE),
    takeEvery(actions.GET_QUOTES, GET_QUOTES),
  ])
}
