import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'

export default class Index extends PureComponent {
  render() {
    return (
      <>
        <Helmet title="Not Found" />
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary">
              <Link style={{ color: 'white' }} to="/account">
                Back Home
              </Link>
            </Button>
          }
        />
      </>
    )
  }
}
