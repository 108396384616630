import { all, takeEvery, put, call } from 'redux-saga/effects'
import uploadCode from 'services/veriglif'
import actions from './actions'

export function* SEND_CODE(payload) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(uploadCode, payload.code)
  if (success) {
    yield put({
      type: 'veriglif/SET_STATE',
      payload: {
        authorized: true,
      },
    })
  } else {
    yield put({
      type: 'veriglif/SET_STATE',
      payload: {
        authorized: false,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SEND_CODE, SEND_CODE)])
}
