export default async function getLeftMenuData() {
  return [
    {
      title: 'New Quote',
      key: 'newquote',
      icon: 'icmn icmn-quotes-left',
      url: '/account/newquote',
      hide: false,
    },
    {
      title: 'Qualify and Snapshot',
      key: 'instants',
      icon: 'icmn icmn-sphere',
      children: [
        {
          key: 'instantsMyStudies',
          title: 'My Studies',
          url: '/account/instants',
        },
        {
          key: 'instantsMyQuotes',
          title: 'My Quotes',
          url: '/account/instants/myquotes',
        },
      ],
    },
    {
      title: 'Via',
      key: 'via',
      icon: 'icmn icmn-earth',
      children: [
        {
          key: 'viaMyStudies',
          title: 'My Studies',
          url: '/account/via',
        },
      ],
    },
    // {
    //   title: 'Veriglif',
    //   key: 'veriglif',
    //   icon: 'icmn icmn-database',
    //   children: [
    //     {
    //       key: 'veriglifDashboard',
    //       title: 'Dashboard',
    //       url: '/account/veriglif',
    //     },
    //     {
    //       key: 'veriglifNewStudy',
    //       title: 'New Study',
    //       url: '/account/veriglif/newstudy',
    //     },
    //     {
    //       key: 'veriglifConnect',
    //       title: 'Connect',
    //       url: '/account/veriglif/connect',
    //     },
    //   ],
    // },
    {
      title: 'Analytics',
      key: 'analytcs',
      icon: 'icmn icmn-stats-dots',
      children: [
        {
          key: 'myQuotes',
          title: 'My Quotes',
          url: '/account/analytics/myquotes',
        },
      ],
    },
    {
      title: 'Admin',
      key: 'admin',
      icon: 'icmn icmn-lock',
      children: [
        {
          key: 'adminApprovals',
          title: 'Approvals',
          url: '/admin/approvals',
        },
        {
          key: 'adminAddUser',
          title: 'Add user',
          url: '/admin/adduser',
        },
        {
          key: 'adminDeleteUser',
          title: 'Delete user',
          url: '/admin/deleteuser',
        },
        {
          key: 'assignStudy',
          title: 'Assign study',
          url: '/admin/assignstudy',
        },
        {
          key: 'resetStudyStats',
          title: 'Reset study stats',
          url: '/admin/resetstudystats',
        },
        {
          key: 'removeUser',
          title: 'Remove study user',
          url: '/admin/removeuser',
        },
        {
          title: 'Test data',
          key: 'testdata',
          url: '/account/testdata',
        },
      ],
    },
    {
      title: 'Demo',
      key: 'demo',
      icon: 'icmn icmn-sphere',
      children: [
        {
          key: 'demoProduct',
          title: 'Product',
          url: '/account/demo/product',
        },
        {
          key: 'demoCaseStudies',
          title: 'Case Studies',
          url: '/account/demo/casestudies',
        },
      ],
    },
    {
      title: 'Doc Hub',
      key: 'dochub',
      icon: 'icmn icmn-files-empty',
      url: '/account/dochub',
      hide: false,
    },
  ]
}
