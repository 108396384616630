import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

console.log("navigator.language", navigator.language)

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en_US',
    lng: localStorage.getItem('lng') || navigator.language,
    resources: {
      en_US: {
        translations: require('./locales/en_US/translations.json'),
      },
      de: {
        translations: require('./locales/de/translations.json'),
      },
      th_TH: {
        translations: require('./locales/th_TH/translations.json'),
      },
      id: {
        translations: require('./locales/id/translations.json'),
      },
      "zh-TW": {
        translations: require('./locales/zh_TW/translations.json'),
      },
      //Added to load correctly on Android app for Taiwanese users
      zh_rTW: { 
        translations: require('./locales/zh_TW/translations.json'),
      }
    },
    ns: ['translations'],
    defaultNS: 'translations',
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['b'],
    },
  });

i18n.languages = ['en_US', 'th_TH', 'id', 'de','zh-TW', 'zh-rTW']

export default i18n;
